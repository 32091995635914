import { useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { jobListLens, JOBS_LIST } from './utils';
import {
  JobsListQueryVariables,
  JobSortDirection,
} from 'src/__generated__/graphql';
import { startOfDay } from '@/utils/helper';

export const futureJobsVariables: JobsListQueryVariables = {
  filter: {
    jobStatus: ['claimed', 'submitted', 'pending_invoice', 'invoiced'],
    startGte: startOfDay()?.toISOString() || new Date().toISOString(),
    sortDir: JobSortDirection.Asc,
  },
  first: 2,
};

const useGetFutureJobs = () => {
  const {
    data: futureJobsData,
    fetchMore: _fetchMoreFutureJobs,
    loading: futureJobsLoading,
  } = useQuery(JOBS_LIST, {
    variables: futureJobsVariables,
    fetchPolicy: 'cache-and-network',
  });
  const jobList = useMemo(() => jobListLens(futureJobsData), [futureJobsData]);
  const fetchMoreFutureJobs = useCallback(() => {
    _fetchMoreFutureJobs({
      variables: {
        after:
          futureJobsData?.me?.__typename === 'Customer'
            ? futureJobsData.me.jobs?.pageInfo?.endCursor
            : null,
      },
    });
  }, [_fetchMoreFutureJobs, futureJobsData]);

  return { futureJobsData, jobList, futureJobsLoading, fetchMoreFutureJobs };
};

export default useGetFutureJobs;
