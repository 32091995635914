import {
  ExtraServices,
  Frequency,
  LevelOfExperience,
} from 'src/__generated__/graphql';
import { AvailabilityContent } from '../AvailabilityWindowPicker/utils';

/* eslint-disable no-unused-vars */
export enum SupportedUTMs {
  BadReview = 'br',
  Review = 'r',
  Dashboard = 'd',
  Other = 'other',
}
/* eslint-enable no-unused-vars */

export interface BookAgainContext {
  services?: Array<ExtraServices>;
  numHours?: number;
  availability?: Array<AvailabilityContent>;
  frequency?: Frequency;
  rcEnabled?: boolean;
  // checkbox on page 1 - if checked, we match the user with a cleaner if their
  // preferred cleaner is not available
  matchCleaners?: boolean;
  requestedCleanerIds?: Array<string>;
  singleSelectedCleanerId?: string;
  multiSelectedCleanerId?: string;
  utm?: SupportedUTMs;
  levelOfExperience?: LevelOfExperience;
  levelOfExperienceExperimentAmount?: string | null;
}
