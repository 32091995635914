import React, { useEffect, useMemo } from 'react';
import UserBoundary from '../UserBoundary';
import Footer from './Footer';
import Header from './Header';
import Head from 'next/head';
import { useBrandData } from 'src/hooks/useBrandData';
import clsx from 'clsx';
import { useLayoutControls } from '@/hooks/useLayoutControls';
import { gql } from 'src/__generated__';
import { useQuery } from '@apollo/client';
import { futureJobsVariables } from '../Dashboard/hooks/useGetFutureJobs';
import mixpanel from '@/utils/mixpanel';

const UserDataForMixpanelQuery = gql(/* GraphQL */ `
  query UserDataForMixpanel($filter: BasicJobFilter, $first: Int) {
    me {
      ... on Customer {
        id
        rawId
        numInvoicedJobs
        jobs(filter: $filter, first: $first) {
          edges {
            node {
              id
            }
          }
        }
        chosenCleanerExperienceLevel {
          level
        }
        preferredCleaners {
          id
        }
        forevercleanMembership {
          id
          status
          paidOff
          cancellationFee
        }
        activeVoucher {
          id
        }
        locations {
          id
          state
        }
        recurringContract {
          id
          status
        }
      }
    }
  }
`);

interface LayoutProps {
  children?: any;
  narrow?: boolean;
  menuHidden?: boolean;
  logoLinkDisabled?: boolean;
  isPublic?: boolean;
}

const Layout = ({
  children,
  narrow,
  menuHidden,
  logoLinkDisabled,
  isPublic,
}: LayoutProps) => {
  const { appName } = useBrandData();
  const { width } = useLayoutControls();
  // use the prop if it's available, otherwise look to controls
  // default is 'narrow' in the useLayoutControls
  const layoutWidth = typeof narrow != 'undefined' ? 'narrow' : width;

  const widthStyles = clsx(
    layoutWidth === 'extraNarrow' && 'max-w-[600px]',
    layoutWidth === 'narrow' && 'max-w-[768px]',
    layoutWidth !== 'narrow' &&
      layoutWidth !== 'extraNarrow' &&
      'max-w-[1280px] lg:px-4',
  );

  const { data } = useQuery(UserDataForMixpanelQuery, {
    variables: {
      filter: futureJobsVariables.filter,
      first: 100,
    },
  });

  const mixpanelUser = useMemo(() => {
    if (!data || data?.me?.__typename !== 'Customer') return null;

    return {
      customerId: data.me.rawId,
      brand: appName,
      numInvoicedJobs: data.me?.numInvoicedJobs || 0,
      numUpcomingJobs: data.me.jobs?.edges?.length || 0,
      hasUnusedVoucher: !!data.me.activeVoucher,
      state: data.me.locations?.[0]?.state,
      rcStatus: data.me.recurringContract?.status,
      membershipStatus: data.me.forevercleanMembership?.status,
      hasPastMembership: !!data.me.forevercleanMembership,
      experienceLevel: data.me.chosenCleanerExperienceLevel?.level,
      numPreferredCleaners: data.me.preferredCleaners?.length || 0,
      unpaidETF: data.me.forevercleanMembership?.paidOff
        ? 0
        : data.me.forevercleanMembership?.cancellationFee || 0,
    };
  }, [appName, data]);

  useEffect(() => {
    if (mixpanelUser) mixpanel.register(mixpanelUser);
  }, [mixpanelUser]);

  return (
    <>
      <Head>
        <title>{`${appName}`}</title>
      </Head>
      <div id="app">
        <Header
          menuHidden={menuHidden}
          logoLinkDisabled={logoLinkDisabled}
          narrow={true}
        />
        <div
          // top bar height is 70px
          // on mobile/tablet, use 70, push it further on desktop
          // a little extra breathing room at the top for mobile/tablet layouts
          // on desktop, use 0 since we have the extra 40px margin
          className={clsx(
            widthStyles,
            'sm:mt-[70px] lg:mt-[110px] mx-auto h-full sm:pt-[16px] lg:pt-0',
          )}
        >
          {isPublic ? (
            <>{children}</>
          ) : (
            <>
              <Head>
                {/* Don't index non-public pages */}
                <meta name="robots" content="noindex" />
              </Head>
              <UserBoundary>{children}</UserBoundary>
            </>
          )}
        </div>
        <div className={clsx(widthStyles, 'mx-auto mt-4 mb-4')}>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
