import clsx from 'clsx';
import { ButtonHTMLAttributes, ReactNode } from 'react';
import { FaSpinner } from 'react-icons/fa';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'ghost' | 'link';
  size?: 'sm' | 'md' | 'lg';
  loading?: boolean;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
};

function Button({
  variant = 'primary',
  size = 'md',
  loading,
  leftIcon,
  rightIcon,
  ...props
}: ButtonProps) {
  const baseButtonStyles = clsx(
    variant !== 'link' && 'p-2 px-4',
    `flex items-center justify-center self-start rounded font-semibold disabled:cursor-not-allowed disabled:opacity-50`,
    'focus:outline-2 focus:outline-offset-4 focus:outline-purple',
  );
  const buttonSizeStyles = {
    sm: clsx(variant !== 'link' && 'h-10', 'border'),
    md: clsx(variant !== 'link' && 'h-[52px]', 'border-2'),
    lg: clsx(variant !== 'link' && 'h-[64px]', 'border-2'),
  };
  const buttonColorStyles = {
    primary: `border-[0] bg-purple hover:bg-darkPurple text-white disabled:hover:bg-purple`,
    secondary: `bg-white text-purple border-purple hover:border-darkPurple hover:text-darkPurple hover:bg-purple hover:bg-opacity-5 disabled:hover:bg-white`,
    tertiary: `bg-lightGray text-black border-[0] hover:bg-lightestGray disabled:hover:bg-lightGray`,
    ghost: `border-[0] bg-transparent text-purple hover:bg-purple hover:bg-opacity-10 disabled:hover:bg-transparent`,
    link: `border-[0] bg-transparent text-purple hover:text-darkPurple hover:underline disabled:hover:text-purple`,
  };
  const buttonLoadingStyles = {
    primary: `opacity-50 hover:bg-purple cursor-wait`,
    secondary: `opacity-50 hover:bg-white cursor-wait`,
    tertiary: `opacity-50 hover:bg-lightGray cursor-wait`,
    ghost: `opacity-50 hover:bg-opacity-0 cursor-wait`,
    link: `opacity-50 hover:text-purple cursor-wait`,
  };

  const buttonStyles = clsx(
    baseButtonStyles,
    buttonSizeStyles[size],
    buttonColorStyles[variant],
    props.className,
    loading && buttonLoadingStyles[variant],
  );

  return (
    <button
      {...props}
      disabled={loading || props.disabled}
      className={buttonStyles}
    >
      {leftIcon ? (
        loading ? (
          <FaSpinner className="animate-spin mr-2" aria-hidden />
        ) : (
          <span className="mr-2" aria-hidden>
            {leftIcon}
          </span>
        )
      ) : null}

      {props.children}
      {loading && !leftIcon && !rightIcon && (
        <FaSpinner className="animate-spin ml-2" aria-hidden />
      )}

      {rightIcon ? (
        loading ? (
          <FaSpinner className="animate-spin ml-2" aria-hidden />
        ) : (
          <span className="ml-2" aria-hidden>
            {rightIcon}
          </span>
        )
      ) : null}
    </button>
  );
}

export default Button;
