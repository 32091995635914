import { useApolloClient, type FetchPolicy } from '@apollo/client';
import type { AddToBatch } from '../batch';
import { batch, createIntervalScheduler } from '../batch';
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
} from 'react';
import { gql } from 'src/__generated__';
import { ExperimentLookupConfig } from 'src/__generated__/graphql';
import mixpanel from '@/utils/mixpanel';

type ExperimentRequest = {
  keyWithOptions: ExperimentLookupConfig;
};

type ExperimentClientContext = {
  fetchFlags?: AddToBatch<unknown, ExperimentRequest>;
};

export const EXPERIMENTS_QUERY = gql(/* GraphQL */ `
  query CustomerExperiments($keysWithOptions: [ExperimentLookupConfig]) {
    experiments(keysWithOptions: $keysWithOptions) {
      key
      value
    }
  }
`);

export const ExperimentContext = createContext(
  Object.create(null) as ExperimentClientContext,
);

// fetch updated flags only once per session so that we don't change the experience
// mid usage
export function ExperimentProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [hasFetchedThisSession, updateSession] = useState(false);
  const client = useApolloClient();
  const fetchFlags = useMemo(
    () =>
      batch<unknown | undefined, ExperimentRequest>((requests) => {
        const hasAutoAssignSet = requests.some(
          (request) => request.keyWithOptions.autoAssign !== undefined,
        );
        // If autoAssign is set we need to fetch flags from network so we
        // can assign any cohorts if autoAssign changes
        const fetchPolicy: FetchPolicy =
          hasFetchedThisSession && !hasAutoAssignSet
            ? 'cache-first'
            : 'network-only';

        updateSession(true);
        return client
          .query({
            query: EXPERIMENTS_QUERY,
            fetchPolicy,
            variables: {
              keysWithOptions: requests.reduce((prev, curr) => {
                // Remove duplicates
                if (prev.some((p) => p.key === curr.keyWithOptions.key)) {
                  return prev;
                }

                return [...prev, { ...curr.keyWithOptions }];
              }, [] as ExperimentLookupConfig[]),
            },
          })
          .then(({ data }) =>
            requests
              .map((flag) => flag.keyWithOptions.key)
              .map((key) => data.experiments.find((f) => f.key === key)?.value),
          );
      }, createIntervalScheduler()),
    [client, hasFetchedThisSession],
  );

  return (
    <ExperimentContext.Provider value={{ fetchFlags }}>
      {children}
    </ExperimentContext.Provider>
  );
}

// This is used to make sure we don't try to setState in the hook anywhere in
// response to a promise resolution after a component has been unmounted
function useIsMountedRef() {
  const isMountedRef = useRef<boolean | null>(null);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  });
  return isMountedRef;
}

export function useExperiment<T>(
  keyWithOptions: ExperimentLookupConfig,
  defaultValue: T,
  skip = false,
  onFetched?: () => void,
): T | undefined {
  const [value, setValue] = useState<T | undefined>();
  const { fetchFlags } = useContext(ExperimentContext);
  const isMountedRef = useIsMountedRef();

  // Destructure so we don't have to pass the whole object to useEffect
  // and cause unnecessary fetches
  const { key, autoAssign } = keyWithOptions;

  useEffect(() => {
    if (skip || !fetchFlags) return;

    fetchFlags({
      keyWithOptions: {
        key,
        autoAssign,
      },
    })
      .then((v) => {
        isMountedRef.current && setValue(v as T);
        const mixpanelKey = `exp_${key}`;
        mixpanel.register({
          [mixpanelKey]: v,
        });
      })
      .catch(() => isMountedRef.current && setValue(defaultValue))
      .finally(() => onFetched?.());
  }, [
    fetchFlags,
    skip,
    defaultValue,
    key,
    autoAssign,
    onFetched,
    isMountedRef,
    value,
  ]);

  return skip ? defaultValue : value;
}
